// 用印列表
export const managementColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 50
  },
  {
    title: '印章名称',
    dataIndex: 'sealName',
    key: 'sealName',
    align: 'left',
    slots: { customRender: 'sealName' },
    width: 340
  },

  {
    title: '印章保管员',
    dataIndex: 'trusteeNames',
    key: 'trusteeNames',
    align: 'left',
    slots: { customRender: 'trusteeNames' },
    ellipsis: true,
    width: 150
  },
  {
    title: '蘸墨类型',
    dataIndex: 'dippingType',
    key: 'dippingType',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'dippingType' },
    width: 100
  },
  {
    title: '特权用印人',
    dataIndex: 'sealRelNames',
    key: 'sealRelNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealRelNames' },
    width: 150
  },
  {
    title: '印章状态',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    slots: { customRender: 'status' },
    width: 100
  },
  {
    title: '印章位置',
    dataIndex: 'address',
    key: 'address',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'address' },
    width: 250
  },
  {
    title: '设备编号',
    dataIndex: 'number',
    key: 'number',
    align: 'left',
    slots: { customRender: 'number' },
    width: 150
  },
  {
    title: '连网状态',
    dataIndex: 'deviceState',
    key: 'deviceState',
    align: 'left',
    slots: { customRender: 'deviceState' },
    width: 150
  },

  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]

// 电子围栏
export const fenceColumns = [
  {
    title: '围栏名称',
    dataIndex: 'railName',
    key: 'railName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'railName' },
    width: 160
  },
  {
    title: '围栏中心点',
    dataIndex: 'railAddress',
    key: 'railAddress',
    align: 'left',
    slots: { customRender: 'railAddress' },
    ellipsis: true,
    width: 200
  },
  {
    title: '围栏半径',
    dataIndex: 'railRadius',
    key: 'railRadius',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'railRadius' },
    width: 150
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    width: 100
  }
]
