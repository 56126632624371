import request from '@/utils/request'
// 文件上传地址
export const actionUrl = process.env.VUE_APP_API_BASE_URL + '/file/upload'
const api = {
  file: '/file'
}
// 获取数据
export function getArticleList(params) {
  return request({
    url: `${api.file}/upload`,
    method: 'GET',
    params
  })
}
