<!-- 添加印章页面 -->
<template>
  <div class="page-add">
    <section>
      <a-form layout="vertical" ref="formRef" :rules="rules" :model="modalVal" class="form-add">
        <!-- 印章名称 -->
        <a-form-item label="印章名称" name="sealName" class="form-item">
          <a-input
            v-model:value="modalVal.sealName"
            :maxlength="20"
            placeholder="请输入印章名称"
            class="form-item-width"
          />
        </a-form-item>
        <!-- 印章保管员 -->
        <a-form-item label="印章保管员" class="form-item" name="trusteesList" :autoLink="false">
          <div class="form-tips-view">
            <div
              @click="handleDepartment(1)"
              class="printer-seal"
              :style="{
                padding: modalVal.trusteesList.length ? '6px 24px 1px 10px' : '0 0 0 12px',
                'line-height': modalVal.trusteesList.length ? 'auto' : '32px',
                maxHeight: '100px',
                overflow: 'auto'
              }"
            >
              <template v-if="modalVal.trusteesList.length">
                <div v-for="(item, index) in modalVal.trusteesList" :key="item.dataId" class="pt-tag">
                  <span class="pt-name" :title="item.dataName">{{ item.dataName }}</span>
                  <div @click.stop="deleteSta(index, 1)" class="pt-close">
                    <CloseOutlined />
                  </div>
                </div>
              </template>
              <span v-else>请选择印章保管员</span>
              <DownOutlined class="down-arrow" />
            </div>
          </div>
        </a-form-item>
        <!-- 所属部门-->
        <a-form-item label="所属部门" class="form-item" name="departmentList" :autoLink="false">
          <div class="form-tips-view">
            <div
              @click="handleDepartment(3)"
              class="printer-seal"
              :style="{
                padding: modalVal.departmentList.length ? '6px 24px 1px 10px' : '0 0 0 12px',
                'line-height': modalVal.departmentList.length ? 'auto' : '32px',
                maxHeight: '100px',
                overflow: 'auto'
              }"
            >
              <template v-if="modalVal.departmentList.length">
                <div v-for="(item, index) in modalVal.departmentList" :key="item.dataId" class="pt-tag">
                  <span class="pt-name" :title="item.dataName">{{ item.dataName }}</span>
                  <div @click.stop="deleteSta(index, 3)" class="pt-close">
                    <CloseOutlined />
                  </div>
                </div>
              </template>
              <span v-else>请选择所属部门</span>
              <DownOutlined class="down-arrow" />
            </div>
          </div>
        </a-form-item>
        <!-- 蘸墨类型 -->
        <a-form-item label="蘸墨类型" name="type" class="form-item" v-if="!isElectronicSeal">
          <a-select v-model:value="modalVal.type" placeholder="请选择蘸墨类型" class="form-item-width">
            <a-select-option v-for="item in sealType" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- 特权用印人 -->
        <a-form-item label="特权用印人" class="form-item, seal-location" v-if="!isElectronicSeal">
          <div class="form-tips-view">
            <div
              @click="handleDepartment(2)"
              class="printer-seal select-input"
              :style="{
                padding: modalVal.staffRelVOList.length ? '6px 24px 1px 10px' : '0 0 0 12px',
                'line-height': modalVal.staffRelVOList.length ? 'auto' : '32px',
                maxHeight: '100px',
                overflow: 'auto'
              }"
            >
              <template v-if="modalVal.staffRelVOList.length">
                <div v-for="(item, index) in modalVal.staffRelVOList" :key="item.dataId" class="pt-tag">
                  <span class="pt-name" :title="item.staffName ?? item.dataName">{{
                    item.staffName ?? item.dataName
                  }}</span>
                  <div @click.stop="deleteSta(index, 2)" class="pt-close">
                    <CloseOutlined />
                  </div>
                </div>
              </template>
              <span v-else>请选择特权用印人</span>
              <DownOutlined class="down-arrow" />
            </div>
            <div class="tip-box">
              <a-tooltip placement="left">
                <template #title>
                  <span style="text-align: center; display: inline-block"
                    >特权用印人对A款、Aplus款、简易章筒生效</span
                  >
                </template>
                <img :src="tip" alt="" class="tip-img" />
              </a-tooltip>
            </div>
          </div>
        </a-form-item>

        <!-- 上传印章照片 -->
        <div class="upload-img">
          <p class="title">
            <span class="text">上传印章照片</span>
            <span class="prompt-text">(请上传清晰照片)</span>
          </p>
          <div class="img-con">
            <ImageUpload
              class="upload"
              :action="action"
              v-model:imageUrl="imageUrl"
              v-model:fileList="fileList"
              @fileChange="fileChange"
            ></ImageUpload>
            <span class="example-text">示例：</span>
            <img class="example-img" src="@/assets/images/sealMessage/commonSeal.png" alt="" />
          </div>
        </div>
        <div>
          <!-- 电子围栏 -->
          <section>
            <section class="table-style">
              <div class="title-line">
                <span class="title">添加电子围栏</span>
                <div class="sealAdd" @click="openModal('add')">
                  <img src="@/assets/svg/add.svg" />
                  <span>添加</span>
                </div>
              </div>
              <a-table
                :columns="fenceColumns"
                :dataSource="modalVal.sealRailList"
                :pagination="false"
                @change="fenceHandleTableChange"
                ref="tableRef"
                :scroll="{ x: modalVal.sealRailList.length > 5 ? 400 : 0, y: 290 }"
                :rowKey="
                  (record, index) => {
                    return index
                  }
                "
              >
                <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
                <template #railName="{ text }">
                  <span :title="text">{{ text ?? '-' }}</span>
                </template>
                <template #railAddress="{ text }">
                  <span :title="text">{{ text ?? '-' }}</span>
                </template>
                <template #railRadius="{ text }">
                  <span :title="text">{{ text ? parseInt(text) + '米' : '-' }}</span>
                </template>
                <template #action="{ record }">
                  <a @click="openModal('delete', record)" class="table-text-color">删除</a>
                </template>
              </a-table>
            </section>
            <!-- 电子围栏弹框 -->
            <Update
              v-model:visible="visible"
              :current="current"
              @modalSubmit="fenceSubmit"
              v-model:loading="loading"
              :actionType="actionType"
            />
          </section>
        </div>
      </a-form>
    </section>
    <!-- 确定 -->
    <div class="btn-con">
      <a-button class="btn-cancel" @click="handleCancel">取消</a-button>
      <a-button class="btn-submit" @click="comfirmAdd" :loading="submitLoading">确定</a-button>
    </div>

    <a-modal
      centered
      :title="popText"
      width="655px"
      class="global-range-modal"
      :visible="globalSelectorVisible"
      @cancel="globalSelectorCancel"
      @ok="globalSelectorOk"
    >
      <GlobalSelector
        :visible="globalSelectorVisible"
        :selectedList="selectedList"
        :selectorInfo="selectorInfo"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        @changeData="changeData"
        ref="globalSelector"
      ></GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { actionUrl } from '@/apis/upload.js'
import { roleColumns, fenceColumns } from './columns'
import { defineComponent, reactive, ref, onMounted, toRefs, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { DownOutlined, CloseOutlined, QuestionCircleFilled, PlusOutlined } from '@ant-design/icons-vue'
import { getProcessStaffList, getOrganizational, getDepartmentId, getDepartmentOrStaff } from '@/apis/businessManage'
import { cmsNotice } from '@/utils/utils'
import ImageUpload from '@/components/Upload/uploadImage.vue'
import '@/style/page-add.scss'
import Update from './fence/update.vue'
import { sealInfor } from '@/apis/sealManage'
import { increaseSeal, modifySeal } from '@/apis/sealManagement.js'
import { paginationParams } from '@/utils/constData'
import tip from '@/assets/images/sealManage/tip.png'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'

// 蘸墨类型选择项
const sealType = [
  {
    id: 2,
    name: '非蘸墨印章'
  },
  {
    id: 1,
    name: '蘸墨印章'
  }
]
const sonRef = ref()

export default defineComponent({
  components: {
    DownOutlined,
    CloseOutlined,
    Update,
    ImageUpload,
    GlobalSelector
  },
  setup() {
    const selectorType = ref(1)
    const currentType = ref(-1)
    const renderList = ref([])
    const selectedList = ref([])
    const globalSelectorVisible = ref(false)
    const selectorInfo = ref({})
    const globalSelector = ref(null)
    const fileList = ref([])
    // 保存接口图片
    const imageUrl = ref('')
    const tableRef = ref()
    const model = ref({
      visible: false,
      current: undefined,
      loading: false,
      actionType: 'add'
    })
    const action = actionUrl
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const formRef = ref()

    const modalVal = reactive({
      // 印章名称
      sealName: undefined,
      // 蘸墨类型
      type: undefined,
      // 印章保管人集合
      trusteesList: [],
      // 特权用印人集合
      staffRelVOList: [],
      // 图片id
      imgId: undefined,
      // 印章电子围栏
      sealRailList: [],
      // 电子围栏名称合集
      sealRailNameList: [],
      custodianId: undefined,
      sealStaffList: [],
      departmentId: [],
      departmentList: [] //所属部门
    })
    const state = reactive({
      submitLoading: false,
      fenceColumns,
      roleColumns,
      visible: false,
      current: undefined,
      pagination: {
        ...paginationParams
      },
      actionType: 'add',
      loading: false,
      processStaffList: [],

      type: undefined, //数据查看人类型
      organizationalList: [],

      //使用范围列表
      isElectronicSeal: false, //是否是电子印章
      popText: '', //弹框文字
      isOnlyYDA_J: false, //是否是简易印章
      checkAll: false, // 是否全选
      custodianVis: false //印章保管员弹窗
    })
    const rules = {
      sealName: [
        {
          required: true,
          message: '请输入印章名称'
        },
        {
          // pattern: '^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9]+$',
          // pattern: '^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9（）《》/ ！ "" () \\[ \\]-]+$',
          pattern: '^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9《》/！“”（）【】<>/!""()\\[\\]-]+$',
          message: '印章名称不能有空格,特殊字符',
          trigger: 'change'
        }
      ],
      trusteesList: [
        {
          type: 'array',
          required: true,
          message: '请选择印章保管员'
        }
      ],
      type: [
        {
          type: 'number',
          required: true,
          message: '请选择蘸墨类型'
        }
      ],
      departmentList: [
        {
          type: 'array',
          required: true,
          message: '请选择所属部门'
        }
      ]
    }

    // 详情页数据
    const querySealInfo = async () => {
      if (route.query.sealId == undefined) return
      const res = await sealInfor({ id: route.query.sealId })
      console.log('详情数据', res)
      if (res.success) {
        // 1，印章名称
        modalVal.sealName = res.data.sealName
        // 2，印章保管员
        modalVal.trusteesList = res.data.trusteesList.map(item => {
          item.dataName = item.staffName
          item.dataType = 1
          item.dataId = item.staffId
          item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
          return item
        })
        console.log('印章保管员', modalVal.trusteesList)
        // 3，蘸墨类型
        modalVal.type = res.data.type
        // 4，特权用印人集合
        modalVal.staffRelVOList = res.data.staffRelVOList.map(item => {
          item.dataName = item.staffName
          item.dataType = 1
          item.dataId = item.staffId
          item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
          return item
        })
        console.log('特权用印人集合', modalVal.staffRelVOList)
        // 5，所属部门集合
        const departmentList =
          res.data.departmentId != null
            ? {
                data: {
                  departmentList: [
                    { departmentId: res.data.departmentId, departmentName: res.data.departmentName, dataType: 2 }
                  ]
                }
              }
            : null
        modalVal.departmentList = departmentList ? formateListData(departmentList, 1) : []
        console.log('所属部门集合', modalVal.departmentList)
        imageUrl.value = res.data.fullSealImage
        // 7,电子围栏
        modalVal.sealRailList = res.data.sealRailList
        //印章照片回显
        modalVal.imgId = res.data.sealImageId
        // 收集电子围栏名称
        modalVal.sealRailList.forEach(e => {
          modalVal.sealRailNameList.push(e.railName)
        })
      }
    }

    // 获取上传图片id
    const fileChange = file => {
      console.log('文件上传file', file)
      if (!Object.keys(file).length) {
        modalVal.imgId = null
      } else {
        modalVal.imgId = file.response.data[0].id
      }
      console.log('上传的文件的id', modalVal.imgId)
    }

    // 获取归档审批流程人员
    const getProcessStaffData = async () => {
      const res = await getProcessStaffList()
      state.processStaffList = res.data
    }

    // 特权用印人选择
    const selectedStaffFn = value => {
      modalVal.staffRelVOList = value
    }

    // 多选删除
    const deleteSta = (index, type) => {
      switch (type) {
        case 1:
          //印章保管员
          modalVal.trusteesList.splice(index, 1)
          formRef.value.validate('trusteesList')
          break
        case 2:
          //特权用印人
          modalVal.staffRelVOList.splice(index, 1)
          break
        case 3:
          //所属部门
          modalVal.departmentList.splice(index, 1)
          break
      }
    }

    // 获取人员组织架构
    const getOrganizationalList = async () => {
      const res = await getOrganizational()
      state.organizationalList = dgOrganiz(res.data)
    }

    const dgOrganiz = arr => {
      const fn = list => {
        return list.map(item => {
          if (item.departmentDTOList.length) {
            fn(item.departmentDTOList)
          } else {
            delete item.departmentDTOList
          }
          return item
        })
      }
      return fn(arr)
    }

    const departmentId = ref([])
    // 获取当前用户部门权限
    const getIds = async () => {
      const res = await getDepartmentId()
      setTimeout(() => {
        departmentId.value = getId(res.data, state.organizationalList, 1)
        if (res.data != -1) {
          disableList(departmentId.value, state.organizationalList, 0)
        }
      }, 100)
    }

    // 根据部门id获取全部id
    const getId = (id, list, index) => {
      if (!list) return
      for (let i = 0; i < list.length; i++) {
        if (list[i]?.id == id) return [list[i]?.id]
        if (list[i]?.departmentDTOList) {
          const ids = getId(id, list[i].departmentDTOList, index + 1)
          if (ids) {
            return [list[i].id, ...ids]
          }
        }
      }
    }
    // 禁用其余部门筛选
    const disableList = (ids = [], list, index) => {
      const len = ids?.length
      if (index > len - 1) return
      list?.map(item => {
        item.disabled = item.id !== ids[index]
        if (item.id === ids[index] && item.departmentDTOList) {
          disableList(ids, item.departmentDTOList, index + 1)
        }
        return item
      })
    }

    // 选择适用范围唤起弹框
    const handleDepartment = async type => {
      currentType.value = type
      switch (type) {
        case 1:
          //印章保管员
          selectorType.value = 1
          await getScopeList()
          state.popText = '选择印章保管员'
          selectorInfo.value = {
            placeholder: '请输入员工姓名或部门',
            limitCount: 300,
            abelCheckAll: true,
            ableSelectDep: true,
            ableChangeData: true
          }
          selectedList.value = [...modalVal.trusteesList]
          break
        case 2:
          //特权用印人
          selectorType.value = 1
          await getScopeList()
          state.popText = '选择特权使用人'
          selectorInfo.value = {
            placeholder: '请输入员工姓名或部门',
            abelCheckAll: true,
            ableSelectDep: true,
            ableChangeData: true
          }
          selectedList.value = [...modalVal.staffRelVOList]
          break
        case 3:
          //所属部门
          selectorType.value = 2
          await getScopeList({ departmentId: -1 })
          state.popText = '选择所属部门'
          selectorInfo.value = {
            placeholder: '请输入部门',
            ableSelectDep: true,
            isSelectDept: true,
            limitWarningInfo: '当前已添加至部门上限请删除已选部门后重试',
            limitCount: 1,
            customDefaultBread: [{ id: -1, label: '集团' }]
          }
          selectedList.value = [...modalVal.departmentList]
          break
      }
      globalSelectorVisible.value = true
    }

    // 初始化
    onMounted(() => {
      let sss = route.query.sealId
      state.isOnlyYDA_J = store.state.user.userInfo.result.isOnlyYDA_J
      // 是否是电子印章
      if (route.query.electronicSeal) {
        state.isElectronicSeal = true
      }
      getProcessStaffData()
      getOrganizationalList()
      getIds()
      modalVal.custodianId = undefined
      modalVal.staffRelVOList = []
      querySealInfo()
    })

    // 确定
    const comfirmAdd = () => {
      formRef.value
        .validate()
        .then(() => modalSubmit(modalVal))
        .catch(() => {
          state.loading = false
        })
    }

    // 提交表单
    const modalSubmit = async () => {
      let _trusteeIds = []
      let _sealDataIds = []
      let _sealRelIds = []
      // 印章保管员id集合
      modalVal.trusteesList.forEach(e => {
        _trusteeIds.push(e.dataId)
      })
      //所属部门id集合
      modalVal.departmentList.forEach(e => {
        _sealDataIds.push(e.dataId)
      })
      // 特权用印人id集合
      modalVal.staffRelVOList.forEach(e => {
        _sealRelIds.push(e.dataId)
      })

      if (modalVal.sealName == undefined || _trusteeIds.length == 0 || modalVal.type == undefined) return
      let _params = {
        id: route.query.sealId,
        sealName: modalVal.sealName,
        trusteeIds: _trusteeIds,
        type: modalVal.type,
        departmentId: _sealDataIds.join(),
        sealRelIds: _sealRelIds,
        sealImageId: modalVal.imgId,
        sealRailList: modalVal.sealRailList
      }
      console.log('参数', modalVal.imgId)
      state.submitLoading = true
      if (route.query.sealId === undefined) {
        const res = await increaseSeal(_params)
        if (res.success) {
          cmsNotice('success', '添加成功')
          router.back()
        } else {
          state.submitLoading = false
        }
      } else {
        const res = await modifySeal(_params)
        if (res.success) {
          cmsNotice('success', '修改成功')
          router.back()
        } else {
          state.submitLoading = false
        }
      }
    }

    // 取消提交，返回列表页
    const handleCancel = () => {
      formRef.value.resetFields()
      nextTick(() => {
        formRef.value.clearValidate()
      })
      router.back()
    }

    // 印章保管员弹出
    const toSelectedcustodian = id => {
      state.custodianVis = true
    }

    //保管员确定sealDataVOListtrusteesList
    const custodianOk = data => {
      modalVal.custodianId = data.dataId
      state.custodianVis = false
      formRef.value.validate(['custodianId'])
    }

    // 多选选中数据处理
    const checkData = e => {
      console.log('选中数据处理', e)
      if (state.type === 1) {
        modalVal.departmentList = e
      } else if (state.type === 5) {
        modalVal.staffRelVOList = e
      } else if (state.type == 6) {
        console.log('走这里了', e.length)
        modalVal.trusteesList = e
        if (e.length == 0) {
          formRef.value.validate('trusteesList')
        } else {
          formRef.value.clearValidate('trusteesList')
        }
      }
    }

    const fenceHandleTableChange = ({ current, pageSize }) => {
      modalVal.sealRailList = []
      state.pagination.current = current
      state.pagination.pageSize = pageSize
    }

    // 电子围栏删除
    const openModal = (type, current) => {
      const actionObjFn = {
        // 删除
        delete() {
          Modal.confirm({
            title: '删除电子围栏',
            content: '确定删除电子围栏？',
            centered: true,
            onOk: () => {
              modalVal.sealRailList.forEach((e, index) => {
                if (e.id == current.id) {
                  modalVal.sealRailList.splice(index, 1)
                }
                if (e.railName == current.railName) {
                  modalVal.sealRailNameList.splice(index, 1)
                }
              })
              state.visible = false
              state.loading = false
              cmsNotice('success', '删除成功')
            }
          })
        },
        // 取消
        edit() {
          state.actionType = type
          state.current = current
          state.visible = true
        }
      }
      if (actionObjFn[type]) {
        actionObjFn[type]()
      } else {
        state.actionType = type
        state.current = current
        state.visible = true
      }
    }

    // 电子围栏弹框添加
    const fenceSubmit = value => {
      if (modalVal.sealRailNameList.indexOf(value.railName) == -1) {
        modalVal.sealRailList.push(JSON.parse(JSON.stringify(value)))
        modalVal.sealRailNameList.push(value.railName)
      } else {
        cmsNotice('error', '电子围栏名称重复')
      }
      state.visible = false
      state.loading = false
    }

    //弹窗确定事件
    const globalSelectorOk = () => {
      let arr = [...globalSelector.value.okEvent()]
      transformData(arr).then(res => {
        switch (currentType.value) {
          case 1:
            modalVal.trusteesList = res //印章保管员
            if (modalVal.trusteesList.length == 0) {
              formRef.value.validate('trusteesList')
            } else {
              formRef.value.clearValidate('trusteesList')
            }
            break
          case 2:
            modalVal.staffRelVOList = res //特权用印人
            break
          case 3:
            modalVal.departmentList = arr //所属部门
        }
      })

      globalSelectorCancel()
    }
    //弹窗取消事件
    const globalSelectorCancel = () => {
      globalSelectorVisible.value = false
      globalSelector.value.cancelEvent()
    }
    //去下级
    const toNext = id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }
    //获取搜索信息
    const getSearchInfo = e => {
      console.log('搜索的值嘞', e)
      let data = {
        searchName: e,
        departmentId: selectorType.value == 2 ? -1 : 0
      }
      // 开启切换数据时，不传部门id
      if (selectorInfo.value.ableChangeData) data.departmentId = undefined
      getScopeList(data)
    }
    //处理左侧数据
    const handelData = res => {
      console.log('获取的所有数据', res)
      renderList.value = formateListData(res)
    }
    //弹窗数据
    const getScopeList = async data => {
      let params = {
        selectorType: selectorType.value, //1部门人员2部门3员工
        allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        handelData(res)
      }
    }
    // 切换数据
    const changeData = ({ isOrganizationData, searchValue }) => {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        selectorType.value = 1
      } else {
        //人员数据
        selectorType.value = 3
      }
      getSearchInfo(searchValue)
    }
    return {
      comfirmAdd,
      formRef,
      modalVal,
      rules,
      sealType,
      handleCancel,
      router,
      modalSubmit,
      ...toRefs(state),
      selectedStaffFn,
      handleDepartment,
      deleteSta,
      sonRef,
      toSelectedcustodian,
      fileChange,
      action,
      model,
      custodianOk,
      checkData,
      tableRef,
      fenceSubmit,
      openModal,
      fenceHandleTableChange,
      fileList,
      imageUrl,
      tip,
      selectorInfo,
      globalSelectorOk,
      globalSelector,
      globalSelectorCancel,
      globalSelectorVisible,
      toNext,
      getSearchInfo,
      selectedList,
      renderList,
      currentType,
      selectorType,
      changeData
    }
  }
})
</script>

<style lang="scss" scoped>
@import './add.scss';
</style>
<style lang="less" scoped>
.form-item {
  :deep(.has-error) .form-tips-view .printer-seal {
    border-color: #0A7BFF;
  }
}
</style>
